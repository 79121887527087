import React, { useState, useEffect } from 'react'
import { Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlink } from '@fortawesome/free-solid-svg-icons'

import UserStoreForm from './UserStoreForm'
import { deleteStoreUser, getStoreUsers } from '../../Stores/services'
import { handleError } from '../../../helpers'

export default function UserStoresModal({ visible, onClose, userId }) {
	const [userStores, setUserStores] = useState(null)

	useEffect(() => {
		!userStores && visible && getStoreUsers({
			'filter[user_id]': userId,
			include: 'store'
		})
			.then(res => setUserStores(res.data.data))
			.catch(error => handleError(error))
	}, [ userStores, userId, visible ])

	const handleDelete = id => {
		deleteStoreUser(id)
			.then(() => {
				message.success('Tienda desasociada exitosamente')
				setUserStores(null)
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Código',
			dataIndex: 'store',
			render: t => t ? t.code : <em className='small'>Sin registro</em>
		},
		{
			title: 'Nombre de Tienda',
			dataIndex: 'store',
			render: t => t ? t.name : <em className='small'>Sin registro</em>
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Popconfirm
						title="¿Segura desea desasociar la tienda?"
						okText="Desasociar"
						cancelText="Cancelar"
						onConfirm={() => handleDelete(record.id)}
						okButtonProps={{ danger: true }}
					>
						<Tooltip title="Desasociar">
							<FontAwesomeIcon 
								className="text-link"
								icon={faUnlink}
							/>
						</Tooltip>
					</Popconfirm>
				</React.Fragment>
			)
		}
	]

	const handleClose = () => {
		onClose()
		setUserStores(null)
	}

	return (
		<Modal isOpen={visible} toggle={handleClose}>
			<ModalHeader toggle={handleClose}>Tiendas Asociadas</ModalHeader>
			<ModalBody>
				<Table 
					dataSource={userStores}
					rowKey={record => record.id}
					columns={columns}
					size="small"
					locale={{ emptyText: <Empty description='No tiene tiendas asociadas' imageStyle={{ height: 60 }} /> }}
					loading={!userStores}
				/>
				<UserStoreForm 
					userId={userId}
					reload={() => setUserStores(null)}
				/>
			</ModalBody>
		</Modal>
	)
}